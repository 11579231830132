@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home_background {
  background: linear-gradient(270deg, rgba(18, 16, 29, 0.50) 0%, #12101D 100%), url('./../public/assets/home_bg.jpg') lightgray 50% / cover no-repeat;
  background-position: middle right;
}

.impact_background {
  background: linear-gradient(270deg, #12101D 0%, rgba(18, 16, 29, 0.50) 100%), url('./../public/assets/impact_bg.png') lightgray 50% / cover no-repeat;
}

.contact_button {
  border-radius: 40px;
  background: linear-gradient(279deg, #FFF 0%, #0FF 100%);
}

/* Home Images */
.server_bg {
  background: url('./../public/assets/servers.png');
}

.financial_bg {
  background: url('./../public/assets/financial.png');
}

.property_bg {
  background: url('./../public/assets/property.png');
}

.security_bg {
  background: url('./../public/assets/security_camera.png');
}

.oil_bg {
  background: url('./../public/assets/oil_platform.png');
}